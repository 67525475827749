import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import UpgradeGuide from '@workday/canvas-kit-docs/dist/mdx/9.0-UPGRADE-GUIDE.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const ExternalContent = makeShortcode("ExternalContent");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Overview" mdxType="TabPanel">
      <ExternalContent mdxType="ExternalContent">
        <h2 {...{
          "id": "upgrade-overview"
        }}>{`Upgrade Overview`}</h2>
        <p>{`The table below contains a high-level overview of the updates that rolled out as part of the v9
release. The impact for developers is defined as follows:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9`}</li>
          <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
          <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
          <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring teams to
make major development decisions`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Change`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Short Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Developer Impact`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Design System Site Versioning`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Versioning will be available for documentation on the Canvas Design System site, corresponding to each major Canvas release`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Depth Token Modifications`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Depth tokens will be modified to reduce the harshness/contrast of depth against backgrounds`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Table Refactoring`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Table component will be released in the Preview package with increased flexibility and updated styling`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Combobox Sub-system`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Combobox sub-system will be released to help developers build components and features with Combobox like autocomplete and Select`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Layout Component Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Layout component will be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Drawer Component Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Drawer component in the Labs package will be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Stack Component Removals`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Stack, VStack, HStack components will all be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default Button Type Modifications`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The default button type for all Canvas buttons will be updated from `}<inlineCode parentName="td">{`submit`}</inlineCode>{` to `}<inlineCode parentName="td">{`button`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useTheme`}</inlineCode>{` Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A fix will be implemented to prevent `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` hook from generating an error when the styled function is used without a predefined theme`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` Promotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` hook will be promoted to the Main package`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` Deprecation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` hook will be deprecated in Canvas v9, but will still be available for use`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` will be removed in favor of `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getTheme`}</inlineCode>{`, respectively`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Kit’s TS Compiler Target Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The TS compiler target will be updated from ES5 to ES2019. This will significantly decrease the Canvas Kit bundle size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Toast Component Promotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Toast component in the Labs package will be promoted to the Main package, replacing the current Toast component in Main`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Enhanced QA & Testing Processes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Additional tests will be added to expand testing across different screen sizes and modality as well as expand visual regression testing`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`For a deeper look into the v9 upgrade, check out the guide on the
`}<a parentName="p" {...{
            "href": "#tab=for-developers"
          }}>{`For Developers`}</a>{` tab.`}</p>
      </ExternalContent>
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "upgrade-overview-1"
        }}>{`Upgrade Overview`}</h2>
        <p>{`The table below contains a high-level overview of the updates that rolled out as part of the v9
release. The impact for designers and developers are defined as follows:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9 and/or switch to the new
v9 Canvas Web Figma library`}</li>
          <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
          <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
          <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Change`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Short Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Designer Impact`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Developer Impact`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Web Figma Library Versioning`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Versioning will be available for the Canvas Web Figma library. A new Canvas Web Figma library will be created for each major Canvas release`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Design System Site Versioning`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Versioning will be available for documentation on the Canvas Design System site, corresponding to each major Canvas release`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Depth Token Modifications`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Depth tokens will be modified to reduce the harshness/contrast of depth against backgrounds`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Table Refactoring`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Table component will be released in the Preview package with increased flexibility and updated styling`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Combobox Sub-system`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Combobox sub-system will be released to help developers build components and features with Combobox like autocomplete and select`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Modal Figma Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Modal component will be updated in Figma to include an overlay and align with the expected behavior in Canvas Kit`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Figma Dialog Component`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Dialog component will be added to Canvas Web`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Segmented Control Figma Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Segmented Control component in the Canvas Web Figma library will be updated to reflect the version available in the Preview package`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Status Indicator Figma Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Status Indicator component in the Canvas Web Figma library will be updated to reflect the version available in the Preview package`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Switch Refactoring and Consolidation Figma Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Switch component will be refactored and consolidated into a single Switch component with variants`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Radio Variant Figma Consolidation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Radio component will be updated in the Canvas Web Figma library to consolidate the number of variants available`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox Variant Figma Consolidation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Checkbox component will be updated in the Canvas Web Figma library to consolidate the number of variants available`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Delete Button Figma Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Updated to be structurally consistent to other Buttons (Primary, Secondary, and Tertiary)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Layout Component Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Layout component will be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Drawer Component Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Drawer component in the Labs package will be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Stack Component Removals`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Stack, VStack, HStack components will all be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default Button Type Modifications`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The default button type for all Canvas buttons will be updated from `}<inlineCode parentName="td">{`submit`}</inlineCode>{` to `}<inlineCode parentName="td">{`button`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useTheme`}</inlineCode>{` Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A fix will be implemented to prevent `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` hook from generating an error when the styled function is used without a predefined theme`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` Promotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` hook will be promoted to the Main package`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` Deprecation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` hook will be deprecated in Canvas v9, but will still be available for use`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` Removal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` will removed in favor of `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getTheme`}</inlineCode>{`, respectively`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Kit’s TS Compiler Target Updates`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The TS compiler target will be updated from ES5 to ES2019. This will significantly decrease the Canvas Kit bundle size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Toast Component Promotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Toast component in the Labs package will be promoted to the Main package, replacing the current Toast component in Main`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Enhanced QA & Testing Processes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Additional tests will be added to expand testing across different screen sizes and modality as well as expand visual regression testing`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`For a deeper look into the v9 upgrade, check out the guides on the
`}<a parentName="p" {...{
            "href": "#tab=for-developers"
          }}>{`For Developers`}</a>{` or `}<a parentName="p" {...{
            "href": "#tab=for-designers"
          }}>{`For Designers`}</a>{` tabs.`}</p>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="For Developers" mdxType="TabPanel">
      <UpgradeGuide mdxType="UpgradeGuide" />
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="For Designers" mdxType="TabPanel">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#introduction-to-versioning"
            }}>{`Introduction to Versioning`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getting-started"
            }}>{`Getting Started`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#deciding-when-to-upgrade"
                }}>{`Deciding When to Upgrade`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#preparation-for-the-upgrade"
                }}>{`Preparation for the Upgrade`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#making-the-upgrade"
                }}>{`Making the Upgrade`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#changes-in-canvas-web-v9"
            }}>{`Changes in Canvas Web v9`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#new-components-design"
            }}>{`New Components`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#table"
                }}>{`Table`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#component-updates-design"
            }}>{`Component Updates`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#segmented-control"
                }}>{`Segmented Control`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#status-indicator"
                }}>{`Status Indicator`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modal"
                }}>{`Modal`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#switch"
                }}>{`Switch`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#radio"
                }}>{`Radio`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#checkbox"
                }}>{`Checkbox`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#depth"
                }}>{`Depth`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#delete-button"
                }}>{`Delete Button`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#what-to-expect-next"
            }}>{`What to Expect Next`}</a></li>
        </ul>
        <h2 {...{
          "id": "introduction-to-versioning"
        }}>{`Introduction to Versioning`}</h2>
        <p>{`Canvas versioning improves communication between design and development teams during collaboration
and handover, allowing all parties to work in sync. The process enables Figma users to upgrade their
Figma files to a newer version of the Canvas Web Figma library when they need it through an
automated process that only takes a few minutes to complete.`}</p>
        <p>{`Previously updates were made directly to a single Canvas Web Figma library. This meant that teams
using the Canvas Figma libraries were always designing with the latest version of Canvas, regardless
of the version developers were building with. This method resulted in confusion during the handover
process as designs didn’t always match what was technically feasible at that time.`}</p>
        <p>{`Library versioning empowers teams to control when and how they upgrade to the next version of the
Canvas Web Figma library. It enables the entire team to work together using the same major versions
of the Canvas Design System. Upgrading to a new library version is quick and easy, but there are
things to consider before doing so.`}</p>
        <h2 {...{
          "id": "getting-started"
        }}>{`Getting Started`}</h2>
        <p>{`Resources are available to help teams upgrade their files to a new version of the Canvas Web Figma
library. This upgrade guide will help designers:`}</p>
        <ul>
          <li parentName="ul">{`Decide when to upgrade.`}</li>
          <li parentName="ul">{`Understand how to prepare files for an upgrade.`}</li>
          <li parentName="ul">{`Perform the upgrade.`}</li>
          <li parentName="ul">{`Understand component changes in v9.`}</li>
        </ul>
        <h3 {...{
          "id": "deciding-when-to-upgrade"
        }}>{`Deciding When to Upgrade`}</h3>
        <p>{`It’s important to upgrade as a team so designs handed over can match what's technically feasible
with each version of Canvas. We recommend chatting with your wider team to understand what version
they are currently building with, and when they plan to upgrade to the next version of Canvas. For
some teams this could take a few days, for others it could be weeks. Setting an upgrade date will
keep the team on the same page.`}</p>
        <h3 {...{
          "id": "preparation-for-the-upgrade"
        }}>{`Preparation for the Upgrade`}</h3>
        <p>{`There are things that can be done before upgrading to v9 that will streamline the process and save
time. Updating the covers of your files will help identify what files are planned to upgrade to v9.
This has the added benefit of helping your team differentiate what you plan to maintain going
forward and what you might archive within your team's workspaces to improve findability.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bcdc5c29db220be6d205820edf75ffee/d9ed5/figma-file-covers.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "36.21621621621621%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Example of figma file covers",
                "title": "Example of figma file covers",
                "src": "/static/bcdc5c29db220be6d205820edf75ffee/50383/figma-file-covers.png",
                "srcSet": ["/static/bcdc5c29db220be6d205820edf75ffee/1efb2/figma-file-covers.png 370w", "/static/bcdc5c29db220be6d205820edf75ffee/50383/figma-file-covers.png 740w", "/static/bcdc5c29db220be6d205820edf75ffee/d9ed5/figma-file-covers.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To reduce the time spent addressing breaking changes it’s recommended that the duplicate or saved
version of your files are consolidated to just the key screens that are frequently used to design
with. These key screens can then be updated to handle breaking changes allowing you to continue to
flesh out any future designs whilst cutting back on clutter. All previous designs can be archived
should you need to reference these in the future.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/158cb9698ce98fe48f672d6331652790/d9ed5/figma-file-consolidation.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "38.648648648648646%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Example of figma file consolidation",
                "title": "Example of figma file consolidation",
                "src": "/static/158cb9698ce98fe48f672d6331652790/50383/figma-file-consolidation.png",
                "srcSet": ["/static/158cb9698ce98fe48f672d6331652790/1efb2/figma-file-consolidation.png 370w", "/static/158cb9698ce98fe48f672d6331652790/50383/figma-file-consolidation.png 740w", "/static/158cb9698ce98fe48f672d6331652790/d9ed5/figma-file-consolidation.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "making-the-upgrade"
        }}>{`Making the Upgrade`}</h3>
        <p>{`Upgrading to a new version of Canvas web is quick, it takes about 5 minutes. The process utilizes
`}<a parentName="p" {...{
            "href": "https://help.figma.com/hc/en-us/articles/4404856784663-Swap-style-and-component-libraries"
          }}>{`Figma's Swap Library feature`}</a>{`
and is automated for the most part, but there are some checks you should run to make sure the update
was successful.`}</p>
        <ol>
          <li parentName="ol">{`Locate the file you want to upgrade.`}</li>
          <li parentName="ol">{`Create a duplicate of the file or
`}<a parentName="li" {...{
              "href": "https://help.figma.com/hc/en-us/articles/360038006754-View-a-file-s-version-history"
            }}>{`save a version of it`}</a>{`.`}</li>
          <li parentName="ol">{`Open the file you wish to upgrade.`}</li>
          <li parentName="ol">{`Go to the assets panel.`}</li>
          <li parentName="ol">{`Open your team library (book icon).`}</li>
          <li parentName="ol">{`Select the current Canvas library used in the file.`}</li>
          <li parentName="ol">{`Select the ‘Swap Library’ button.`}</li>
          <li parentName="ol">{`Choose the latest library to swap to.`}</li>
          <li parentName="ol">{`Click the ‘Swap Library’ Button again.`}</li>
          <li parentName="ol">{`Wait for a confirmation from Figma.`}</li>
        </ol>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4bb8bc143ff8accbaabdfa3412bf12db/d9ed5/figma-versioning-steps.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "50.54054054054055%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of steps to take when upgrading a file in figma to a new version of Canvas",
                "title": "illustration of steps to take when upgrading a file in figma to a new version of Canvas",
                "src": "/static/4bb8bc143ff8accbaabdfa3412bf12db/50383/figma-versioning-steps.png",
                "srcSet": ["/static/4bb8bc143ff8accbaabdfa3412bf12db/1efb2/figma-versioning-steps.png 370w", "/static/4bb8bc143ff8accbaabdfa3412bf12db/50383/figma-versioning-steps.png 740w", "/static/4bb8bc143ff8accbaabdfa3412bf12db/d9ed5/figma-versioning-steps.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`All Canvas components used in your file have now been swapped to the latest Canvas Web Library. You
should now review the file and make any appropriate changes to affected components based on the
changelog above, we recommend that you only update key screens in this file and delete any redundant
and interstitial screens to cut down on your upgrade time.`}</p>
        <p>{`Lastly the new library should be set to persist in your team's files, to do this your team's admin
will need to complete the following steps.`}</p>
        <ol>
          <li parentName="ol">{`Click on your Team in Figma.`}</li>
          <li parentName="ol">{`Go to the Settings tab.`}</li>
          <li parentName="ol">{`Select ‘Enable Libraries for all team files’.`}</li>
          <li parentName="ol">{`Turn off the old library.`}</li>
          <li parentName="ol">{`Activate the new library.`}</li>
        </ol>
        <h2 {...{
          "id": "changes-in-canvas-web-v9"
        }}>{`Changes in Canvas Web v9`}</h2>
        <p>{`Breaking changes are commonplace in major version releases. This means something has changed
visually and/or technically. Breaking changes need to be considered during an upgrade and sometimes
action is required to consume them successfully. Below is a list of components affected in this
release that require action to consume them appropriately within your files.`}</p>
        <h2 id="new-components-design">New Components</h2>
        <h3 {...{
          "id": "table"
        }}>{`Table`}</h3>
        <p>{`A new Table component will be available, to match the new Table component offered in Canvas Kit
Preview package. The new Table component is a nested component consisting of a Table, Column, and
Rows, from which designers can create tables. The new component takes advantage of Autolayout
properties and nested components in order to make table design quicker and more streamlined.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0731e8314d7da970cbcd507f29a1e9c9/d9ed5/new-table-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "36.21621621621621%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of the new table component available in Canvas Web v9",
                "title": "illustration of the new table component available in Canvas Web v9",
                "src": "/static/0731e8314d7da970cbcd507f29a1e9c9/50383/new-table-v9.png",
                "srcSet": ["/static/0731e8314d7da970cbcd507f29a1e9c9/1efb2/new-table-v9.png 370w", "/static/0731e8314d7da970cbcd507f29a1e9c9/50383/new-table-v9.png 740w", "/static/0731e8314d7da970cbcd507f29a1e9c9/d9ed5/new-table-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`For more complex use cases with the need for built-in features and functionality, please refer to
Tables 2.0
(`}<a parentName="p" {...{
            "href": "https://d2ohtkv486av5n.cloudfront.net/?path=/story/table--columns-with-custom-initial-width"
          }}>{`Storybook`}</a>{`
and
`}<a parentName="p" {...{
            "href": "https://www.figma.com/file/YuJ7rOSVpwygflXd1VeaQ1/Generated-UI?node-id=1-39&t=7fDAgvoBae41Hv0v-0"
          }}>{`Generated UI Figma component`}</a>{`).`}</p>
        <h2 id="component-updates-design">Component Updates</h2>
        <h3 {...{
          "id": "segmented-control"
        }}>{`Segmented Control`}</h3>
        <p>{`The updates to the Segmented Control component is a visual and technical breaking change as it has
been redesigned to incorporate additional variants to handle text, which was introduced in code with
the Canvas v8 release. Custom overrides made to this component will revert back to a default state.
Please customize this component when upgrading to v9 to address this breaking change.`}</p>
        <p>{`The components footprint is also slightly larger and may require additional spacing to match Canvas
spacing guidelines to address the vertical rhythm of this component with other elements in your
designs.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7636ab04d09abe2fe0c4c247b741f932/d9ed5/segmented-control-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "24.054054054054056%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the segmented control component Canvas Web v9",
                "title": "illustration of updates made to the segmented control component Canvas Web v9",
                "src": "/static/7636ab04d09abe2fe0c4c247b741f932/50383/segmented-control-update-v9.png",
                "srcSet": ["/static/7636ab04d09abe2fe0c4c247b741f932/1efb2/segmented-control-update-v9.png 370w", "/static/7636ab04d09abe2fe0c4c247b741f932/50383/segmented-control-update-v9.png 740w", "/static/7636ab04d09abe2fe0c4c247b741f932/d9ed5/segmented-control-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To upgrade Segmented Control in v9:`}</p>
        <ol>
          <li parentName="ol">{`Perform a library swap from the current Canvas Web v8 Figma library to the new Canvas Web v9
Figma library.`}</li>
          <li parentName="ol">{`Restore any previous overrides to icons or states.`}</li>
          <li parentName="ol">{`Account for vertical alignment using the `}<a parentName="li" {...{
              "href": "/styles/tokens/space"
            }}>{`Canvas Space Tokens`}</a>{`.`}</li>
        </ol>
        <h3 {...{
          "id": "status-indicator"
        }}>{`Status Indicator`}</h3>
        <p>{`The Status Indicator component in Canvas Web v9 is a visual and technical breaking change. The text
size has been increased and set to sentence case for improved accessibility and localization
capabilities. As a result, the component’s height has increased slightly. Spacing may need to be
updated to match Canvas spacing guidelines to address the vertical rhythm of this component with
other elements in your designs.`}</p>
        <p>{`Custom overrides made to this component will persist once users swap over to the new Canvas Web v9
Figma library. However, designers will also need to perform an additional instance swap afterwards
to ensure pre-set customizations persist if the Status Indicator icon variant is being used.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f4e6a0767c444abe6598081e205e8f87/d9ed5/status-indicator-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "21.62162162162162%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Status Indicator component Canvas Web v9",
                "title": "illustration of updates made to the Status Indicator component Canvas Web v9",
                "src": "/static/f4e6a0767c444abe6598081e205e8f87/50383/status-indicator-update-v9.png",
                "srcSet": ["/static/f4e6a0767c444abe6598081e205e8f87/1efb2/status-indicator-update-v9.png 370w", "/static/f4e6a0767c444abe6598081e205e8f87/50383/status-indicator-update-v9.png 740w", "/static/f4e6a0767c444abe6598081e205e8f87/d9ed5/status-indicator-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To upgrade Status Indicator in v9:`}</p>
        <ol>
          <li parentName="ol">{`Perform a library swap from the current Canvas Web v8 Figma library to the new Canvas Web v9
Figma library.`}</li>
          <li parentName="ol">{`Select a Status Indicator with the layer name ❌ `}<em parentName="li">{`Status Indicator`}</em>{` ❌.`}</li>
          <li parentName="ol">{`Use the
‘`}<a parentName="li" {...{
              "href": "https://help.figma.com/hc/en-us/articles/360040449873-Select-layers-and-objects#:~:text=want%20to%20select-,Select%20all%20layers,-The%C2%A0Edit"
            }}>{`Select All with same Instance`}</a>{`’
action in Figma to select all deprecated instances on the page.`}</li>
          <li parentName="ol">{`Manually swap to ‘Status Indicator’ using the ‘Swap Instance’ dropdown in the right panel.`}</li>
          <li parentName="ol">{`There should be no breaking changes.`}</li>
        </ol>
        <h3 {...{
          "id": "modal"
        }}>{`Modal`}</h3>
        <p>{`The Modal component in Canvas Web v9 is a visual breaking change. It has been updated to include a
modal overlay and the medium size variant has been removed as developers don’t have pre-set sizes.
Modals’ height and width can be adjusted manually to fit most use cases by expanding the body of the
Modal using Figma’s Autolayout settings.`}</p>
        <p>{`All custom overrides and content will persist. However migrating to this version of Modal will
require you to adjust the size of the overlay to cover the entire viewport, keeping the Modal
component centered on screen. Instances that don’t require this overlay should be swapped to the new
Dialog component.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/abd96babbb2a0e40273bc46d603d2b6d/d9ed5/modal-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Modal component Canvas Web v9",
                "title": "illustration of updates made to the Modal component Canvas Web v9",
                "src": "/static/abd96babbb2a0e40273bc46d603d2b6d/50383/modal-update-v9.png",
                "srcSet": ["/static/abd96babbb2a0e40273bc46d603d2b6d/1efb2/modal-update-v9.png 370w", "/static/abd96babbb2a0e40273bc46d603d2b6d/50383/modal-update-v9.png 740w", "/static/abd96babbb2a0e40273bc46d603d2b6d/d9ed5/modal-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To upgrade Modal in v9:`}</p>
        <ol>
          <li parentName="ol">{`Perform a library swap from the current Canvas Web v8 Figma library to the new Canvas Web v9
Figma library.`}</li>
          <li parentName="ol">{`Remove custom overlay if already applied.`}</li>
          <li parentName="ol">{`Expand the Modal component to fill the entire screen with the Modal Overlay.`}</li>
        </ol>
        <h3 {...{
          "id": "switch"
        }}>{`Switch`}</h3>
        <p>{`The Switch component updates are a technical breaking change. Switch was built as multiple
components, which have been consolidated into a single component with variants to match the standard
of other components in the Canvas Web Figma Library. As a result the Switch component will revert to
its default No Label state and will require designers to reconfiguration it in their files to match
previous designs when upgrading to v9.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/fbc795d4168e529139ee1a576eacfd30/d9ed5/switch-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "20.81081081081081%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Switch component Canvas Web v9",
                "title": "illustration of updates made to the Switch component Canvas Web v9",
                "src": "/static/fbc795d4168e529139ee1a576eacfd30/50383/switch-update-v9.png",
                "srcSet": ["/static/fbc795d4168e529139ee1a576eacfd30/1efb2/switch-update-v9.png 370w", "/static/fbc795d4168e529139ee1a576eacfd30/50383/switch-update-v9.png 740w", "/static/fbc795d4168e529139ee1a576eacfd30/d9ed5/switch-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To upgrade switch in v9:`}</p>
        <ol>
          <li parentName="ol">{`Perform a library swap from the current Canvas Web v8 Figma library to the new Canvas Web v9
Figma library.`}</li>
          <li parentName="ol">{`Restore any previous overrides to text or states.`}</li>
        </ol>
        <h3 {...{
          "id": "radio"
        }}>{`Radio`}</h3>
        <p>{`The Radio component in Canvas v9 has been refactored with Boolean properties to reduce a large
number of redundant variants and to minimize future bugs from large variant sets. Library swapping
from v8 to v9 will not have any breaking changes.`}</p>
        <p>{`Custom overrides made to this component will persist once users swap over to the new Canvas Web v9
Figma library. However, designers will also need to perform an additional instance swap afterwards
to ensure pre-set customizations persist if the Text="False" variant is being used.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0a8db5f8dd481317b3e7fab911740c94/d9ed5/radio-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "34.054054054054056%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Radio component Canvas Web v9",
                "title": "illustration of updates made to the Radio component Canvas Web v9",
                "src": "/static/0a8db5f8dd481317b3e7fab911740c94/50383/radio-update-v9.png",
                "srcSet": ["/static/0a8db5f8dd481317b3e7fab911740c94/1efb2/radio-update-v9.png 370w", "/static/0a8db5f8dd481317b3e7fab911740c94/50383/radio-update-v9.png 740w", "/static/0a8db5f8dd481317b3e7fab911740c94/d9ed5/radio-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To upgrade Radio in v9:`}</p>
        <ol>
          <li parentName="ol">{`Perform a library swap from the current Canvas Web v8 Figma library to the new Canvas Web v9
Figma library.`}</li>
          <li parentName="ol">{`Select a Radio with the layer name ❌ `}<em parentName="li">{`Radio/Single`}</em>{` ❌.`}</li>
          <li parentName="ol">{`Use the
‘`}<a parentName="li" {...{
              "href": "https://help.figma.com/hc/en-us/articles/360040449873-Select-layers-and-objects#:~:text=want%20to%20select-,Select%20all%20layers,-The%C2%A0Edit"
            }}>{`Select All with same Instance`}</a>{`’
action in Figma to select all deprecated instances on the page.`}</li>
          <li parentName="ol">{`Manually swap to ‘Radio/Single’’ using the ‘Swap Instance’ dropdown in the right panel.`}</li>
          <li parentName="ol">{`There should be no breaking changes.`}</li>
        </ol>
        <h3 {...{
          "id": "checkbox"
        }}>{`Checkbox`}</h3>
        <p>{`The Checkbox component in Canvas Web v9 has been refactored to reduce a large number of redundant
variants and associated minimize bugs, no changes have been made to the existing functionality of
the Checkbox component.`}</p>
        <p>{`Custom overrides made to this component will persist once users swap over to the new Canvas Web v9
Figma library. However, designers will also need to perform an additional instance swap afterwards
to ensure pre-set customizations persist if the Text="False" variant is being used.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5e5a5999846ff4e7b02867900e0dd8b0/d9ed5/checkbox-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "34.054054054054056%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Checkbox component Canvas Web v9",
                "title": "illustration of updates made to the Checkbox component Canvas Web v9",
                "src": "/static/5e5a5999846ff4e7b02867900e0dd8b0/50383/checkbox-update-v9.png",
                "srcSet": ["/static/5e5a5999846ff4e7b02867900e0dd8b0/1efb2/checkbox-update-v9.png 370w", "/static/5e5a5999846ff4e7b02867900e0dd8b0/50383/checkbox-update-v9.png 740w", "/static/5e5a5999846ff4e7b02867900e0dd8b0/d9ed5/checkbox-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`To upgrade Checkbox in v9:`}</p>
        <ol>
          <li parentName="ol">{`Perform a library swap from the current Canvas Web v8 Figma library to the new Canvas Web v9
Figma library.`}</li>
          <li parentName="ol">{`Select a Checkbox with the layer name ❌ `}<em parentName="li">{`Checkbox/Single`}</em>{` ❌.`}</li>
          <li parentName="ol">{`Use the
‘`}<a parentName="li" {...{
              "href": "https://help.figma.com/hc/en-us/articles/360040449873-Select-layers-and-objects#:~:text=want%20to%20select-,Select%20all%20layers,-The%C2%A0Edit"
            }}>{`Select All with same Instance`}</a>{`’
action in Figma to select all deprecated instances on the page.`}</li>
          <li parentName="ol">{`Manually swap to 'Checkbox/Single’ using the ‘Swap Instance’ dropdown in the right panel.`}</li>
          <li parentName="ol">{`Turn the boolean prop labeled '👀 Text' to `}<strong parentName="li">{`OFF`}</strong>{` to hide input text.`}</li>
          <li parentName="ol">{`There should be no breaking changes.`}</li>
        </ol>
        <h3 {...{
          "id": "depth"
        }}>{`Depth`}</h3>
        <p>{`Depth tokens have been modified to reduce the starkness of depth against light backgrounds. The
changes to depth will be applied automatically once you upgrade to the Canvas Web v9 Library.`}</p>
        <p>{`Designers should review active design files and modify the depth levels their designs are using
accordingly. Designers using depth tokens for components or designs created outside of Canvas should
also accept the changes in the Canvas Tokens Figma library.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cc71caa47dc2b48e3708a1c6363f413b/d9ed5/depth-updates-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "47.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Depth Tokens in Canvas Web v9",
                "title": "illustration of updates made to the Depth Tokens in Canvas Web v9",
                "src": "/static/cc71caa47dc2b48e3708a1c6363f413b/50383/depth-updates-v9.png",
                "srcSet": ["/static/cc71caa47dc2b48e3708a1c6363f413b/1efb2/depth-updates-v9.png 370w", "/static/cc71caa47dc2b48e3708a1c6363f413b/50383/depth-updates-v9.png 740w", "/static/cc71caa47dc2b48e3708a1c6363f413b/d9ed5/depth-updates-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "delete-button"
        }}>{`Delete Button`}</h3>
        <p>{`The Delete Button component has been upgraded to include extra-small variants to align with other
existing button variants. No action is required to consume the updated delete buttons on Canvas Web
v9`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c0c6d7ecc7f1937b9b394a020151109f/d9ed5/delete-button-update-v9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.027027027027025%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "illustration of updates made to the Delete Button component in Canvas Web v9",
                "title": "illustration of updates made to the Delete Button component in Canvas Web v9",
                "src": "/static/c0c6d7ecc7f1937b9b394a020151109f/50383/delete-button-update-v9.png",
                "srcSet": ["/static/c0c6d7ecc7f1937b9b394a020151109f/1efb2/delete-button-update-v9.png 370w", "/static/c0c6d7ecc7f1937b9b394a020151109f/50383/delete-button-update-v9.png 740w", "/static/c0c6d7ecc7f1937b9b394a020151109f/d9ed5/delete-button-update-v9.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "what-to-expect-next"
        }}>{`What to Expect Next`}</h2>
        <p>{`The latest version of Canvas will receive minor updates and bug-fixes to improve the version you’re
building with. These won’t cause breaking changes so please continue to accept these updates as they
will not change the version of Canvas you’re building with.`}</p>
        <p>{`When a new version of Canvas becomes available in the future you can follow the same steps you took
here to upgrade to the latest version of Canvas Web.`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      